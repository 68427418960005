import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://app.enbrocrm.com/clients/validvat',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

export default {
    getEnterpriseData(enterprise_number) {
        return apiClient.get(`/${enterprise_number}`);
    },
};
