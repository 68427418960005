<template>
    <Header :title="$tc('create_client', 1)" :backLinkText="$tc('back_to_client', 2)" :backLinkURL="{ name: 'clients' }" />

    <section class="mt-10">
        <form @submit.prevent="createClient" class="divide-y divide-fibonaki-border">
            <fieldset class="pb-6">
                <header>
                    <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('general', 1) }}</h3>
                </header>

                <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                    <div class="col-span-12">
                        <RadioButtonGroup :label="$tc('client_type', 1)" :options="clientTypeOptions" valueProperty="value" displayProperty="label" v-model="client.type" />
                    </div>

                    <template v-if="client.type === 'b2b'">
                        <div class="col-span-12">
                            <InputWithButton :label="$tc('enterprise_number', 1)" buttonLabel="Get enterprise data" :onClick="getEnterpriseData" type="text" id="enterprise_number" v-model="client.enterprise_number" :error="errors.enterprise_number" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('business_name', 1)" type="text" id="companyName" v-model="client.business_name" :error="errors.business_name" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('legal_entity_type', 1)" type="text" id="companyType" v-model="client.legal_entity_type" :error="errors.legal_entity_type" />
                        </div>
                        <div class="col-span-3">
                            <Input :label="$tc('phone', 1)" type="text" id="phone" v-model="client.phone" :error="errors.phone" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('email_address', 1)" type="text" id="email" v-model="client.email" :error="errors.email" />
                        </div>
                        <div class="col-span-3">
                            <Select :label="$tc('language', 1)" id="languageOfClient" :options="languageOptions" displayProperty="display_name" valueProperty="name" v-model="client.language" />
                        </div>
                    </template>

                    <template v-else>
                        <div class="col-span-6">
                            <Input :label="$tc('first_name', 1)" type="text" id="first_name" v-model="client.first_name" :error="errors.first_name" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('last_name', 1)" type="text" id="last_name" v-model="client.last_name" :error="errors.last_name" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('phone', 1)" type="text" id="phone" v-model="client.phone" :error="errors.phone" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('email_address', 1)" type="text" id="email" v-model="client.email" :error="errors.email" />
                        </div>
                        <div class="col-span-6">
                            <Input :label="$tc('date_of_birth', 1)" type="date" id="date_of_birth" v-model="client.date_of_birth" :error="errors.date_of_birth" />
                        </div>
                    </template>
                </div>
            </fieldset>

            <fieldset class="py-6">
                <header>
                    <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('physical_address', 1) }}</h3>
                </header>

                <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                    <div class="col-span-8">
                        <Input :label="$tc('street', 1)" type="text" id="street" v-model="client.physical_address.street" :error="errors.street" />
                    </div>
                    <div class="col-span-2">
                        <Input :label="$tc('number', 1)" type="text" id="number" v-model="client.physical_address.number" :error="errors.number" />
                    </div>
                    <div class="col-span-2">
                        <Input :label="$tc('addition', 1)" type="text" id="addition" v-model="client.physical_address.addition" :error="errors.addition" />
                    </div>
                    <div class="col-span-6">
                        <SelectWithSearch :label="$tc('city', 1)" id="physical_address_postal_code_id" v-model="client.physical_address.postal_code_id" :options="cityOptions" displayProperty="display_name" valueProperty="id" :minLengthForDropdown="3" />
                    </div>

                    <div class="col-span-4">
                        <Input :label="$tc('country', 1)" type="text" id="country" v-model="client.physical_address.country" :error="errors.country" />
                    </div>
                </div>
            </fieldset>

            <fieldset class="py-6">
                <header>
                    <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('billing_address', 1) }}</h3>
                </header>

                <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                    <div class="col-span-12">
                        <Checkbox :label="$tc('billing_same_as_physical', 1)" v-model="client.same_billing_address" />
                    </div>

                    <template v-if="!client.same_billing_address">
                        <div class="col-span-8">
                            <Input :label="$tc('street', 1)" type="text" id="street" v-model="client.billing_address.street" :error="errors.street" />
                        </div>
                        <div class="col-span-2">
                            <Input :label="$tc('number', 1)" type="text" id="number" v-model="client.billing_address.number" :error="errors.number" />
                        </div>
                        <div class="col-span-2">
                            <Input :label="$tc('addition', 1)" type="text" id="addition" v-model="client.billing_address.addition" :error="errors.addition" />
                        </div>
                        <div class="col-span-6">
                            <SelectWithSearch :label="$tc('city', 1)" id="billing_address.postal_code_id" v-model="client.billing_address.postal_code_id" :options="cityOptions" displayProperty="display_name" valueProperty="id" :minLengthForDropdown="3" />
                        </div>
                        <div class="col-span-4">
                            <Input :label="$tc('country', 1)" type="text" id="country" v-model="client.billing_address.country" :error="errors.country" />
                        </div>
                    </template>
                </div>
            </fieldset>

            <fieldset class="py-6">
                <div class="flex justify-between mb-3">
                    <header>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ $tc('contact', client.contacts.length) }}</h3>
                    </header>
                    <button type="button" class="text-sm leading-6 font-medium text-blue-500 flex items-center" @click="addContact">{{ $tc('add', 1) }} {{ $tc('contact', 1).toLowerCase() }}</button>
                </div>

                <section class="space-y-6">
                    <div v-for="(contact, contactIdx) in client.contacts" :key="contactIdx">
                        <h4 v-show="client.contacts.length > 1" class="mb-3 text-sm leading-6 font-medium text-gray-500">
                            {{ $tc('contact', 1) }} {{ contactIdx + 1 }} <span @click="deleteContact(contactIdx)" class="text-blue-500 cursor-pointer select-none">({{ $tc('delete', 1) }})</span>
                        </h4>

                        <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                            <div class="col-span-12">
                                <RadioButtonGroup :label="$tc('gender', 1)" id="gender" :options="genderOptions" valueProperty="value" displayProperty="label" v-model="contact.gender" />
                            </div>
                            <div class="col-span-6">
                                <Input :label="$tc('first_name', 1)" type="text" id="first_name" v-model="contact.first_name" />
                            </div>
                            <div class="col-span-6">
                                <Input :label="$tc('last_name', 1)" type="text" id="last_name" v-model="contact.last_name" />
                            </div>
                            <div class="col-span-3">
                                <Input :label="$tc('phone', 1)" type="text" id="phone" v-model="contact.phone" />
                            </div>
                            <div class="col-span-6">
                                <Input :label="$tc('email_address', 1)" type="text" id="email" v-model="contact.email" />
                            </div>
                            <div class="col-span-3">
                                <Input :label="$tc('date_of_birth', 1)" type="date" id="date_of_birth" optional v-model="contact.date_of_birth" />
                            </div>
                            <div class="col-span-9">
                                <Input :label="$tc('function', 1)" type="text" id="function" v-model="contact.function" />
                            </div>
                            <div class="col-span-3">
                                <Select :label="$tc('language', 1)" id="language_of_contact" :options="languageOptions" displayProperty="display_name" valueProperty="name" v-model="contact.language" />
                            </div>
                        </div>
                    </div>
                </section>
            </fieldset>

            <fieldset class="pt-6">
                <SubmitButton :label="$tc('create', 1) + ' ' + $tc('client', 1)" submittingLabel="Creating client..." />
            </fieldset>
        </form>
    </section>
</template>

<script>
    // import * as yup from 'yup';
    import AddressService from '@/services/AddressService';
    import EnterpriseService from '@/services/EnterpriseService';

    // const CreateClientValidationSchema = yup.object().shape({
    //     type: yup.string().required(),
    //     enterprise_number: yup.string(),
    //     business_name: yup.string(),
    //     legal_entity_type: yup.string(),
    //     phone: yup.string().required(),
    //     email: yup.string().required().email(),
    //     language: yup.string().required(),
    //     first_name: yup.string(),
    //     last_name: yup.string(),
    //     date_of_birth: yup.date(),
    //     physical_address: yup.object({
    //         street: yup.string().required(),
    //         number: yup.string().required(),
    //         addition: yup.string(),
    //         city: yup.string().required(),
    //         country: yup.string().required(),
    //     }),
    //     contacts: yup.array().of(
    //         yup.object().shape({
    //             first_name: yup.string().required(),
    //             last_name: yup.string().required(),
    //             phone: yup.string().required(),
    //             email: yup.string().required().email(),
    //             date_of_birth: yup.date().required(),
    //             function: yup.date().required(),
    //             language: yup.date().required(),
    //         })
    //     ),
    // });

    export default {
        data() {
            return {
                cityOptions: [],
                clientTypeOptions: [
                    { label: this.$tc('b2b'), value: 'b2b' },
                    { label: this.$tc('b2c'), value: 'b2c' },
                ],
                client: {
                    type: 'b2b',
                    same_billing_address: true,
                    enterprise_number: '0479199992',
                    physical_address: {},
                    billing_address: {},
                    language: 'nl',
                    contacts: [
                        {
                            function: '',
                            first_name: '',
                            last_name: '',
                            phone: '',
                            gender: 'male',
                            email: '',
                            language: 'nl',
                            date_of_birth: '',
                        },
                    ],
                },
                languageOptions: [
                    { id: 1, name: 'nl', display_name: 'Nederlands' },
                    { id: 2, name: 'fr', display_name: 'Français' },
                    { id: 3, name: 'en', display_name: 'English' },
                ],
                genderOptions: [
                    { label: this.$tc('male', 1), value: 'male' },
                    { label: this.$tc('female', 1), value: 'female' },
                    { label: this.$tc('other', 1), value: 'other' },
                ],
                errors: {},
            };
        },
        created() {
            this.getCities();
        },
        methods: {
            async getCities() {
                const response = await AddressService.getCities();
                this.cityOptions = response.data.cities.map((city) => {
                    return {
                        id: city.id,
                        display_name: `${city.sub_city} (${city.zip})`,
                    };
                });
            },
            async getEnterpriseData() {
                try {
                    const response = await EnterpriseService.getEnterpriseData(this.client.enterprise_number);
                    //app.enbrocrm.com/clients/validvat/0417741089
                    // const data = response.data;

                    // this.client = {
                    //     ...this.client,
                    //     business_name: data.denomination,
                    //     legal_entity_type: data.legalentitytype.name,
                    //     enterprise_number: data.enterprise_number,
                    //     physical_address: {
                    //         data.address.zipcode
                    //         data.address.municipality_nl,
                    //         data.address.street_nl,
                    //         data.address.house_number,
                    //         data.address.box,
                    //     }
                    // }
                    console.log(response);
                } catch (err) {
                    if (err.response) {
                        console.log(err.response.data.message);
                    } else if (err.request) {
                        console.log(err.request);
                    } else {
                        console.log(err);
                    }
                }
            },
            addContact() {
                this.client.contacts.unshift({
                    function: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                    gender: 'male',
                    email: '',
                    language: 'nl',
                    date_of_birth: '',
                });
            },
            deleteContact(index) {
                if (this.client.contacts.length > 1) {
                    this.client.contacts.splice(index, 1);
                }
            },

            async createClient() {
                const new_client = await this.$store.dispatch('clients/createClient', { company_id: this.$store.getters['auth/organisation_id'], client: this.client });
                this.$router.push({ name: 'clientDetails', params: { client_id: new_client.id } });
            },
        },
    };
</script>
